<template>
  <b-row no-gutters>
    <b-col cols="12" class="border-bottom">
      <h5 class="font-weight-bold p-2 mb-0 text-center bg-light">
        {{ subject }}
      </h5>
    </b-col>
    <b-col cols="12" class="p-2">
      <!-- INTRO -->
      <intro
        class="mb-3"
        :name="client.firstName"
        :text="
          $t('emails.clientInstructions.intro', locale, {
            accommodationName: accommodation.name,
          })
        "
      />

      <!-- CHECK-IN AND CHECKOUT TIMES -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.clientInstructions.timesTitle", locale) }}
        </h4>
        <b-row class="d-flex justify-content-around">
          <!-- CHECKIN -->
          <b-col cols="5" class="border border-solid p-1 text-center">
            <p class="font-weight-bolder mb-1">
              {{ $t("Checkin", locale) }}
            </p>
            <feather-icon
              icon="ClockIcon"
              class="text-primary mb-1"
              size="32"
            />
            <p class="mb-50">
              {{ accommodationArrivalDay }}
            </p>
            <p
              v-if="accommodationArrivalTime"
              class="d-flex justify-content-center"
            >
              <small>{{ accommodationArrivalTime }}</small>
            </p>
          </b-col>

          <!-- CHECKOUT -->
          <b-col cols="5" class="border border-solid p-1 text-center">
            <p class="font-weight-bolder mb-1">
              {{ $t("Checkout", locale) }}
            </p>
            <feather-icon
              icon="ClockIcon"
              class="text-primary mb-1"
              size="32"
            />
            <p class="mb-50">
              {{ accommodationDepartureDay }}
            </p>
            <p
              v-if="accommodationDepartureTime"
              class="d-flex justify-content-center"
            >
              <small>{{ accommodationDepartureTime }}</small>
            </p>
          </b-col>
        </b-row>
        <p class="text-center mt-1">
          <small>
            {{ $t("emails.clientInstructions.timesNote", locale) }}
          </small>
        </p>
      </div>

      <!-- ACCOMMODATION LOCATION -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.clientInstructions.locationTitle", locale) }}
        </h4>
        <template v-if="accommodationHasDiseminatedAddress">
          <p>
            {{
              $t("emails.clientInstructions.locationOutskirts", locale, {
                city: accommodationLocation.city,
              })
            }}:
          </p>
          <p v-if="gpsCoordinates">
            {{ gpsCoordinates }}
          </p>
        </template>
        <template v-else>
          <p>
            {{ $t("emails.clientInstructions.locationFullAddress", locale) }}:
          </p>
          <p>
            {{ fullAddress }}
          </p>
        </template>
        <p class="text-center text-primary">
          <b-button variant="primary" block @click="onGoogleMpasLinkClicked">
            {{ $t("emails.clientInstructions.locationGoogleMaps", locale) }}
          </b-button>
        </p>
      </div>

      <!-- KEYS COLLECTION -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.clientInstructions.keysTitle", locale) }}
        </h4>
        <b-row class="d-flex justify-content-around">
          <!-- SELF CHECK-IN -->
          <b-col
            cols="5"
            class="border border-solid p-1 cursor-pointer"
            :class="{ 'border-primary': content.selfCheckin }"
            @click="content.selfCheckin = true"
          >
            <p class="font-weight-semibold mb-1">
              <b-form-checkbox
                v-model="content.selfCheckin"
                class="pointer-events-none"
              >
                {{ $t("emails.clientInstructions.keysSelfCheckin", locale) }}
              </b-form-checkbox>
            </p>
            <div class="text-center">
              <feather-icon
                icon="KeyIcon"
                class="text-primary my-2"
                size="32"
              />
              <p class="mb-50">
                <small class="text-left font-weight-bolder">
                  {{ $t("emails.clientInstructions.keysLocation", locale) }}
                </small>
              </p>
              <b-form-textarea
                v-model="content.keysLocation"
                rows="1"
                size="sm"
              />
              <p class="mt-1 mb-50">
                <small class="font-weight-bolder">
                  {{ $t("emails.clientInstructions.keysLockboxCode", locale) }}
                </small>
              </p>
              <b-form-input
                v-model="content.lockboxCode"
                size="sm"
                placeholder="0000"
                class="text-center"
              />
            </div>
          </b-col>

          <!-- OWNER CHECK-IN -->
          <b-col
            cols="5"
            class="border border-dashed p-1 cursor-pointer"
            :class="{ 'border-primary': content.ownerCheckin }"
            @click="content.ownerCheckin = true"
          >
            <p class="font-weight-semibold mb-1">
              <b-form-checkbox
                v-model="content.ownerCheckin"
                class="pointer-events-none"
              >
                {{ $t("emails.clientInstructions.keysOwnerCheckin", locale) }}
              </b-form-checkbox>
            </p>
            <div class="text-center">
              <feather-icon
                icon="UserIcon"
                class="text-primary my-2"
                size="32"
              />
              <p class="mb-50">
                <small class="font-weight-bolder">{{
                  $t("Recorda", locale)
                }}</small>
              </p>
              <b-form-textarea
                v-model="content.ownerInstructions"
                rows="5"
                size="sm"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- PENDING PAYMENTS -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.clientInstructions.pendingPaymentsTitle", locale) }}
        </h4>
        <b-list-group flush class="mb-1">
          <!-- ACCOMMODATION -->
          <b-list-group-item
            class="d-flex justify-content-between align-items-center px-0"
          >
            <div>
              <div class="font-weight-bold">
                {{ $t("Allotjament", locale) }}
              </div>
              <small>{{ formatCurrency(bookingSubtotal) }}</small>
            </div>
            <b-badge v-if="bookingSubtotalPending > 0" pill variant="danger">
              {{ $t("Pendent", locale) }}
              {{ formatCurrency(bookingSubtotalPending) }}
            </b-badge>
            <b-badge v-else pill variant="success">
              {{ $t("Pagat", locale) }}
            </b-badge>
          </b-list-group-item>

          <!-- SECURITY DEPOSIT -->
          <b-list-group-item
            v-if="hasChargableSecurityDeposit"
            class="d-flex justify-content-between align-items-center px-0"
          >
            <div>
              <div class="font-weight-bold">
                {{ $t("Fiança", locale) }}
              </div>
              <small>{{ formatCurrency(securityDepositPrice) }}</small>
            </div>
            <b-badge
              v-if="securityDepositPendingPrice > 0"
              pill
              variant="danger"
            >
              {{ $t("Pendent", locale) }}
              {{ formatCurrency(securityDepositPendingPrice) }}
            </b-badge>
            <b-badge v-else pill variant="success">
              {{ $t("Pagat", locale) }}
            </b-badge>
          </b-list-group-item>

          <!-- CHARGABLE SERVICES -->
          <b-list-group-item
            v-for="(chargableService, index) in chargableServices"
            :key="`chargable-service-${index}`"
            class="d-flex justify-content-between align-items-center px-0"
          >
            <div>
              <div class="font-weight-bold">
                {{ getServiceName(chargableService.service, locale) }}
              </div>
              <small>
                {{ getBookingServiceTotalPriceText(chargableService) }}
              </small>
            </div>
            <b-badge
              v-if="getServicePendingPrice(chargableService) > 0"
              pill
              variant="danger"
            >
              {{ $t("Pendent", locale) }}
              {{ formatCurrency(getServicePendingPrice(chargableService)) }}
            </b-badge>
            <b-badge v-else pill variant="success">
              {{ $t("Pagat", locale) }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>

        <!-- PAY NOW BUTTON -->
        <b-button
          v-if="tpvLink"
          block
          variant="primary"
          :href="tpvLink"
          target="blank"
        >
          {{ $t("Paga ara", locale) }}
        </b-button>
      </div>

      <!-- ONSITE PAYMENTS -->
      <div
        v-if="!hasChargableSecurityDeposit || unchargableServices.length"
        class="mb-4"
      >
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.clientInstructions.onsitePaymentsTitle", locale) }}
        </h4>
        <b-list-group flush>
          <!-- SECURITY DEPOSIT -->
          <b-list-group-item
            v-if="hasSecurityDeposit && !hasChargableSecurityDeposit"
            class="d-flex justify-content-between align-items-center px-0"
          >
            <div>
              <div class="font-weight-bold">
                {{ $t("Fiança", locale) }}
              </div>
              <small>{{ formatCurrency(securityDepositPrice) }}</small>
            </div>
            <b-badge pill variant="danger">
              {{ $t("Pendent", locale) }}
            </b-badge>
          </b-list-group-item>

          <!-- UNCHARGABLE SERVICES -->
          <b-list-group-item
            v-for="(unchargableService, index) in unchargableServices"
            :key="`chargable-service-${index}`"
            class="d-flex justify-content-between align-items-center px-0"
          >
            <div>
              <div class="font-weight-bold">
                {{ getServiceName(unchargableService.service, locale) }}
              </div>
              <small>{{
                getBookingServiceUnitPriceText(unchargableService)
              }}</small>
            </div>
            <b-badge pill variant="danger">
              {{ $t("Pendent", locale) }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </div>

      <!-- OPTIONAL HIRABLE SERVICES -->
      <div v-if="optionalAccommodationServices.length" class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.clientInstructions.optionalServicesTitle", locale) }}
        </h4>
        <b-list-group flush>
          <b-list-group-item
            v-for="(
              accommodationService, index
            ) in optionalAccommodationServices"
            :key="`chargable-service-${index}`"
            class="d-flex justify-content-between align-items-center px-0"
          >
            <div>
              <div class="font-weight-bold">
                {{ getServiceName(accommodationService.service, locale) }}
              </div>
              <small>
                {{
                  getAccommodationServiceTotalOrUnitPriceText(
                    accommodationService
                  )
                }}
              </small>
            </div>
            <b-badge pill variant="info">
              {{ $t("Opcional", locale) }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
      </div>

      <!-- CONTACT -->
      <div class="mb-4">
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.clientInstructions.contactTitle", locale) }}
        </h4>
        <b-row class="mb-2">
          <b-col cols="12">
            <p class="mb-1">
              {{ $t("emails.clientInstructions.contactNonUrgent", locale) }}
            </p>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="mr-2">
                    <feather-icon icon="MailIcon" size="20" />
                  </span>
                  <div>
                    <h6 class="mb-25">
                      {{ $t("Email", locale) }}
                    </h6>
                    <b-card-text>hola@foravilarentals.com</b-card-text>
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="mr-2">
                    <feather-icon icon="PhoneIcon" size="20" />
                  </span>
                  <div>
                    <h6 class="mb-25">
                      {{ $t("Telèfon", locale) }}
                    </h6>
                    <b-card-text>
                      +34 971 541 247 (WhatsApp,
                      {{ $t("Trucada", locale) }})
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12">
            <p class="mb-1">
              {{ $t("emails.clientInstructions.contactUrgent", locale) }}
            </p>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="mr-2">
                    <feather-icon icon="PhoneCallIcon" size="20" />
                  </span>
                  <div>
                    <h6 class="mb-25">
                      {{ $t("Telèfon d'assistència", locale) }}
                    </h6>
                    <b-card-text>
                      {{ AGENCY_24H_ASSISTANCE_NUMBER }} (WhatsApp, SMS,
                      {{ $t("Trucada", locale) }})
                    </b-card-text>
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="mr-2">
                    <feather-icon icon="AlertTriangleIcon" size="20" />
                  </span>
                  <div>
                    <h6 class="mb-25">
                      {{ $t("Telèfon d'emergències", locale) }}
                    </h6>
                    <b-card-text>112</b-card-text>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>

      <!-- MORE QUESTIONS -->
      <div>
        <h4 class="font-weight-bold mb-2">
          {{ $t("emails.clientInstructions.questionsTitle", locale) }}
        </h4>
        <p>
          {{ $t("emails.clientInstructions.questionsText", locale) }}
        </p>
      </div>

      <!-- INTRANET BUTTON -->
      <b-button
        v-if="intranetLink"
        block
        variant="primary"
        class="mt-2"
        :href="intranetLink"
        target="blank"
      >
        {{ $t("emails.common.intranetAccessButton", locale) }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BBadge,
  BCardText,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import {
  formatCurrency,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getAccommodationServiceClientTotalOrUnitPriceText,
  getBookingServiceClientTotalPrice,
  getBookingServiceClientUnitPriceText,
  getBookingServicePendingPrice,
  getFullAddress,
  getPaymentFrequencyUnit,
  getServiceName,
  getTpvLink,
  getKeysLocationName,
} from "@/utils/methods";
import Intro from "@/views/bookings/booking/components/mail/Intro.vue";
import {
  AGENCY_GUESTS_INTRANET_LOGIN,
  AGENCY_GUESTS_INTRANET_REGISTER,
  AGENCY_24H_ASSISTANCE_NUMBER,
} from "@appConfig";

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BBadge,
    BCardText,
    BFormInput,
    BFormTextarea,
    BButton,
    Intro,
  },
  props: {
    locale: {
      type: String,
      default: "en-GB",
    },
  },
  data() {
    return {
      content: {},
      getServiceName,
      AGENCY_24H_ASSISTANCE_NUMBER,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingSubtotal() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    bookingSubtotalPending() {
      return this.$store.getters["booking/subtotalPending"];
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
    pendingWithSecurityDeposit() {
      return this.pending + this.securityDepositPendingPrice;
    },
    chargableServices() {
      return this.$store.getters["booking/chargableServices"];
    },
    chargableServicesPendingPrice() {
      if (!this.chargableServices.length) {
        return 0;
      }

      return this.chargableServices.reduce((sum, chargableService) => {
        return sum + this.getServicePendingPrice(chargableService);
      }, 0);
    },
    contentChargableServices() {
      return this.chargableServices.map((bookingService) => ({
        name: getServiceName(bookingService.service, this.locale),
        price: this.getBookingServiceTotalPrice(bookingService),
        pendingPrice: this.getServicePendingPrice(bookingService),
      }));
    },
    unchargableServices() {
      return this.$store.getters["booking/unchargableServices"];
    },
    contentUnchargableServices() {
      const services = [];

      // Include security deposit if needed
      if (this.hasSecurityDeposit && !this.hasChargableSecurityDeposit) {
        const securityDepositService = this.securityDepositServices[0];
        const { service, paymentFrequency } = securityDepositService || {};

        services.push({
          name: getServiceName(service, this.locale),
          price: this.securityDepositPrice,
          unit: this.getPaymentFrequencyUnit(paymentFrequency) || "",
        });
      }

      // Include the rest of the services
      this.unchargableServices.forEach((bookingService) =>
        services.push({
          name: getServiceName(bookingService.service, this.locale),
          price: bookingService.pvpPrice,
          unit:
            this.getPaymentFrequencyUnit(bookingService.paymentFrequency) || "",
        })
      );

      return services;
    },
    globalServices() {
      return this.$store.getters["globalServices/services"];
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    accommodationDetails() {
      return this.accommodation?.details || null;
    },
    faqs() {
      return this.$store.getters["accommodation/faqs"];
    },
    contentFaqs() {
      if (!this.faqs.length) {
        return [];
      }

      return this.faqs.filter((faq) => {
        const validLanguage =
          faq.language === this.locale.substring(0, 2).toLowerCase();
        const validScope = ["ALL", "CLIENT"].includes(faq.scope);
        return validLanguage && validScope;
      });
    },
    optionalAccommodationServices() {
      const optionalServices =
        this.$store.getters["accommodation/optionalServices"];

      const chargableServicesCodes = new Set(
        this.chargableServices.map(
          (chargableService) => chargableService.service?.code
        )
      );

      const unchargableServicesCodes = new Set(
        this.unchargableServices.map(
          (unchargableService) => unchargableService.service?.code
        )
      );

      const filteredOptionalServices = optionalServices.filter(
        (optionalService) => {
          const { code } = optionalService.service || {}; // Extract the service code using destructuring
          return (
            !chargableServicesCodes.has(code) &&
            !unchargableServicesCodes.has(code)
          );
        }
      );

      const mergedServices = [
        ...filteredOptionalServices,
        ...this.globalServices,
      ];

      const mergedServicesCodes = new Set(
        mergedServices.map((mergedService) => mergedService.service?.code)
      );

      // Convert the Set back to an array of unique services
      const uniqueServices = Array.from(mergedServicesCodes).map((code) => {
        return mergedServices.find(
          (mergedService) => mergedService.service?.code === code
        );
      });

      return uniqueServices;
    },
    contentOptionalAccommodationServices() {
      if (!this.optionalAccommodationServices.length) {
        return [];
      }

      return this.optionalAccommodationServices.map((accommodationService) => ({
        name: getServiceName(accommodationService.service, this.locale),
        price: accommodationService.price,
        unit:
          this.getPaymentFrequencyUnit(accommodationService.paymentFrequency) ||
          "",
      }));
    },
    accommodationLocation() {
      return this.accommodation?.location || null;
    },
    accommodationHasDiseminatedAddress() {
      return (
        this.accommodationLocation &&
        this.accommodationLocation.addressPolygon &&
        this.accommodationLocation.addressPlot
      );
    },
    gpsCoordinates() {
      if (!this.accommodationLocation) return null;
      return `${this.accommodationLocation.latitude}, ${this.accommodationLocation.longitude}`;
    },
    accommodationArrivalDay() {
      return formatDateStringToDate(this.booking.checkin);
    },
    accommodationArrivalTime() {
      return this.details?.accommodationArrivalTime
        ? `${this.details?.accommodationArrivalTime} h`
        : this.$t("emails.common.timesFrom16", this.locale);
    },
    accommodationDepartureDay() {
      return formatDateStringToDate(this.booking.checkout);
    },
    accommodationDepartureTime() {
      return this.details?.accommodationDepartureTime
        ? `${this.details?.accommodationDepartureTime} h`
        : this.$t("emails.common.timesUpTo10", this.locale);
    },
    fullAddress() {
      return (
        getFullAddress(this.accommodationLocation, this.locale) ||
        this.$t(
          "emails.clientInstructions.locationAddressNotAvailable",
          this.locale
        )
      );
    },
    googleMapsLink() {
      if (!this.gpsCoordinates) return null;
      return `http://www.google.com/maps/place/${this.accommodationLocation.latitude},${this.accommodationLocation.longitude}`;
    },
    securityDepositServices() {
      return this.$store.getters["booking/securityDepositServices"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    securityDepositPendingPrice() {
      return this.$store.getters["booking/securityDepositPendingPrice"];
    },
    intranetLink() {
      if (!this.client) return null;
      if (!this.client.user)
        return `${AGENCY_GUESTS_INTRANET_REGISTER}/${this.client.uuid}?l=${this.locale}`;
      return `${AGENCY_GUESTS_INTRANET_LOGIN}?l=${this.locale}`;
    },
    tpvLinkAmount() {
      return !this.hasSecurityDeposit || !this.hasChargableSecurityDeposit
        ? this.pending
        : this.pendingWithSecurityDeposit;
    },
    tpvLink() {
      const { locale, tpvLinkAmount, accommodation, booking, client } = this;
      const { checkin, checkout, localizator } = booking || {};

      if (tpvLinkAmount <= 0) {
        return null;
      }

      return getTpvLink({
        locale,
        accommodation: accommodation?.name || null,
        localizator: localizator || null,
        checkin: formatDateStringToDatabaseDate(checkin),
        checkout: formatDateStringToDatabaseDate(checkout),
        amount: tpvLinkAmount > 0 ? (tpvLinkAmount / 100).toFixed(2) : null,
        name: client?.fullName || null,
        comment: `${this.$t("Pagament pendent", locale)}+${localizator}`,
      });
    },
    subject() {
      return `${this.$t("emails.clientInstructions.subject", this.locale, {
        localizator: this.booking.localizator,
      })}`;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "content.selfCheckin": function (value) {
      this.content.ownerCheckin = !value;
    },
    // eslint-disable-next-line func-names
    "content.ownerCheckin": function (value) {
      this.content.selfCheckin = !value;
    },
    content: {
      deep: true,
      handler() {
        this.$emit("content-updated", this.content);
      },
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.content = {
        locale: this.locale,
        subject: this.subject,
        clientName: this.client?.firstName || null,
        clientEmail: this.client?.email || null,
        accommodationName: this.accommodation?.name || null,
        checkinDay: this.accommodationArrivalDay,
        checkinTime: this.details?.accommodationArrivalTime || null,
        checkoutDay: this.accommodationDepartureDay,
        checkoutTime: this.details?.accommodationDepartureTime || null,
        addressType: this.accommodationHasDiseminatedAddress
          ? "DISSEMINATED"
          : "ADDRESS",
        gpsCoordinates: this.gpsCoordinates,
        fullAddress: this.fullAddress,
        city: this.accommodationLocation.city,
        googleMapsLink: this.googleMapsLink,
        selfCheckin: !!this.accommodationDetails?.selfCheckin,
        ownerCheckin: !this.accommodationDetails?.selfCheckin,
        lockboxCode: this.accommodationDetails?.lockboxCode || null,
        keysLocation: getKeysLocationName(
          this.contract?.keysLocation || null,
          this.locale
        ),
        ownerInstructions: this.$t(
          "emails.clientInstructions.keysOwnerInstructions",
          this.locale
        ),
        accommodationPrice: this.bookingSubtotal,
        accommodationPendingPrice: this.bookingSubtotalPending,
        hasSecurityDeposit: this.hasSecurityDeposit,
        hasChargableSecurityDeposit: this.hasChargableSecurityDeposit,
        securityDepositPrice: this.securityDepositPrice,
        securityDepositPendingPrice: this.securityDepositPendingPrice,
        chargableServices: this.contentChargableServices,
        unchargableServices: this.contentUnchargableServices,
        optionalServices: this.contentOptionalAccommodationServices,
        paymentLink: this.tpvLink,
        intranetLink: this.intranetLink,
        faqs: this.contentFaqs,
      };
    },
    formatCurrency(value) {
      return formatCurrency(value) || null;
    },
    getServicePendingPrice(bookingService) {
      return getBookingServicePendingPrice(bookingService, this.booking.nights);
    },
    getBookingServiceTotalPrice(bookingService) {
      return getBookingServiceClientTotalPrice(
        bookingService,
        this.booking.nights
      );
    },
    getBookingServiceTotalPriceText(bookingService) {
      return formatCurrency(this.getBookingServiceTotalPrice(bookingService));
    },
    getBookingServiceUnitPriceText(bookingService) {
      return getBookingServiceClientUnitPriceText(bookingService, this.locale);
    },
    getAccommodationServiceTotalOrUnitPriceText(accommodationService) {
      return getAccommodationServiceClientTotalOrUnitPriceText(
        accommodationService,
        this.booking.nights,
        this.locale
      );
    },
    getPaymentFrequencyUnit(code) {
      return getPaymentFrequencyUnit(code, this.locale);
    },
    onGoogleMpasLinkClicked() {
      window.open(this.googleMapsLink, "_blank");
    },
  },
};
</script>
